import React, { useState } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Tree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import OrgTree from 'react-org-tree';

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomNode = ({ data }) => {
    const nodeStyle = {
      backgroundColor: data.style?.backgroundColor || "transparent",
      // Add any other styles you need for nodes here
    };
  
    return (
      <div style={nodeStyle}>
        {data.label}
      </div>
    );
  };
  

const MyScaffold = () => {
    const [data, setData] = useState({
        id: 0,
        label: "Supply Chain Risk",
        children: [
          {
            id: 1,
            label: "External Risk",
            children: [
              {
                id: 2,
                label: "Geopolitical",
                children: [
                  {
                    id: 3,
                    label: "Political",
                    children: [
                      {
                        id: 4,
                        label: "Elections",
                      },
                      {
                        id: 5,
                        label: "Regime Change",
                      },
                      {
                        id: 6,
                        label: "Civil War",
                      },
                    ],
                  },
                  {
                    id: 7,
                    label: "Warfare",
                    children: [
                      {
                        id: 8,
                        label: "Terrorism",
                      },
                      {
                        id: 9,
                        label: "Cyber Attacks",
                      },
                      {
                        id: 10,
                        label: "Wars",
                      },
                    ],
                  },
                  {
                    id: 11,
                    label: "Financial Markets",
                    children: [
                      {
                        id: 12,
                        label: "Capital Markets",
                      },
                      {
                        id: 13,
                        label: "Interest Rates",
                      },
                      {
                        id: 14,
                        label: "Currency",
                      },
                      {
                        id: 15,
                        label: "Banking Stability",
                      },
                    ],
                  },
                  {
                    id: 16,
                    label: "Trade & Economic Policies",
                    children: [
                      {
                        id: 17,
                        label: "Protectionism",
                      },
                      {
                        id: 18,
                        label: "Trade Agreements & MoUs",
                      },
                      {
                        id: 19,
                        label: "Budgets",
                      },
                      {
                        id: 20,
                        label: "Industrial Policies",
                      },
                    ],
                  },
                ],
              },
              {
                id: 21,
                label: "Climate",
                children: [
                  {
                    id: 22,
                    label: "Natural Calamities",
                    children: [
                      {
                        id: 23,
                        label: "Cyclone",
                      },
                      {
                        id: 24,
                        label: "Flood",
                      },
                      {
                        id: 25,
                        label: "Tsunami",
                      },
                      {
                        id: 26,
                        label: "Drought",
                      },
                    ],
                  },
                  {
                    id: 27,
                    label: "Global Warming",
                    children: [
                      {
                        id: 28,
                        label: "Sea Level",
                      },
                      {
                        id: 29,
                        label: "Temperature Rise",
                      },
                      {
                        id: 30,
                        label: "Wildfire",
                      },
                      {
                        id: 31,
                        label: "Unseasonal Weather",
                      },
                    ],
                  },
                ],
              },
              {
                id: 32,
                label: "Supplier",
                children: [
                  {
                    id: 33,
                    label: "Factory/Operations",
                    children: [
                      {
                        id: 34,
                        label: "Industrial Actions",
                      },
                      {
                        id: 35,
                        label: "Accidents",
                      },
                      {
                        id: 36,
                        label: "Capacity",
                      },
                      {
                        id: 37,
                        label: "Procurement & Supply",
                      },
                    ],
                  },
                  {
                    id: 38,
                    label: "Financial Risks",
                    children: [
                      {
                        id: 39,
                        label: "P&L",
                      },
                      {
                        id: 40,
                        label: "Leverage",
                      },
                      {
                        id: 41,
                        label: "Cashflow",
                      },
                    ],
                  },
                  {
                    id: 42,
                    label: "Corporate Risks",
                    children: [
                      {
                        id: 43,
                        label: "JVs & Partnerships",
                      },
                      {
                        id: 44,
                        label: "Reputation",
                      },
                      {
                        id: 45,
                        label: "Environmental & Sustainability",
                      },
                      {
                        id: 46,
                        label: "Governance",
                      },
                      {
                        id: 47,
                        label: "Ownership",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 48,
            label: "Internal Risk",
            children: [
              {
                id: 49,
                label: "Factory/Operation Risk",
                children: [
                  {
                    id: 50,
                    label: "Industrial Actions",
                  },
                  {
                    id: 51,
                    label: "Actions",
                  },
                  {
                    id: 52,
                    label: "Capacity",
                  },
                  {
                    id: 53,
                    label: "Procurement",
                  },
                ],
              },
              {
                id: 54,
                label: "Financial Risk",
                children: [
                  {
                    id: 55,
                    label: "P&L",
                  },
                  {
                    id: 56,
                    label: "CashFlow",
                  },
                  {
                    id: 57,
                    label: "Leverage",
                  },
                ],
              },
            ],
          },
        ],
      });

    const [horizontal] = useState(true);
    const [collapsable] = useState(true);
    const [expandAll] = useState(false);
  


  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div
              style={{
                gridGap: "10px",
                justifyContent: "flex-start",
                width: "100%",
                textAlign: "left",
                overflow: "hidden",
                overflowY: "auto",
                height: "calc(100vh - 100px)",
              }}
            >
              <div>
                


<div className="">
      <OrgTree
        data={data}
        horizontal={horizontal}
        collapsable={collapsable}
        expandAll={expandAll}
        renderContent={(data) => {
          return data.label;
        }}
        onClick={(e, data) => {
          // todo: handle click event
        }}
          
      >

      </OrgTree>
    </div>


              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default MyScaffold;
