import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from "react-router-dom";
import DataJson from '../../data/risk.json'
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// const eventData = [
//   {
//     "Event":"economic decline"
//   },
//   {
//     "Event": "Fire in the ship"
//   },
//   {
//     "Event": "Russia-Ukraine war"
//   },
//   {
//     "Event": "Lawsuit"
//   },
//   {
//     "Event": "Low supply demand"
//   },
//   {
//     "Event": "High fuel cost"
//   },
//   {
//     "Event": "Covid-19"
//   },
//   {
//     "Event": "Labor strike"
//   },
//   {
//     "Event": "Global supply chain disruptions"
//   },
//   {
//     "Event": "Oversupply of container ships"
//   },
//   {
//     "Event": "Low oil price"
//   },
//   {
//     "Event": "Increased competition"
//   },
//   {
//     "Event": "Decline in container freight rates"
//   },
//   {
//     "Event": "Sanctions on China"
//   },
//   {
//     "Event": "US-China war"
//   },
//   {
//     "Event": "Economic slowdown"
//   },
//   {
//     "Event": "Oversupply of container shipping capacity"
//   },
//   {
//     "Event": "Ongoing shortage of shipping containers"
//   },
//   {
//     "Event": "Ongoing global shipping crisis"
//   },
//   {
//     "Event": "Freight rates to surge"
//   },
//   {
//     "Event": "Employee layoff"
//   },
//   {
//     "Event": "Sanctions"
//   },
//   {
//     "Event": "Rising inflation"
//   }
  
// ]

// const riskcategory = [
//   {
//     "risk_category": "Supplier Risk"
//   },
//   {
//     "risk_category": "Natural Cause"
//   },
//   {
//     "risk_category": "Geo-Political"
//   },
//   {
//     "risk_category": "Political"
//   },
//   {
//     "risk_category": "pandemic"
//   }
  
// ]

const sector = [
  { "sector": "Marine sector text" },
  { "sector": "Road and Rail sector text" },
  { "sector": "Air Freight and Logistics sector text" },
  { "sector": "Commercial Services and Supplies sector text" },
  { "sector": "Construction and Engineering sector text" },
  { "sector": "Diversified Financial Services sector text" }
]

const SuppyChainRisk = () => {
  //   const [activeTab, setActiveTab] = useState("tab4"); // Initialize the active tab
  const { target } = useParams();
  const [activeTaget, setactiveTaget] = useState(""); // Initialize the active tab
  const history = useHistory();
  const [tabValue, setTabValue] = useState("Risk Category"); // Initialize with 'tab1' as the default tab
  const [dataItem,setDataItem] = useState(DataJson)

  // console.log(dataItem)
  // const riskcategoryDataCate = dataItem.filter((item) =>item.categoryType === "Risk Category")
  const riskcategoryData = dataItem.map((item) =>item.risk_category)

  // const riskcategoryDataEvent = dataItem.filter((item) =>item.categoryType === "Event")
  const eventcategoryData = dataItem.map((item) =>item.event)

  const uniqueriskcategoryData = [...new Set(riskcategoryData)];
  const uniqueeventcategoryData = [...new Set(eventcategoryData)];

  const riskcategory = uniqueriskcategoryData.map((item) => ({
    risk_category: item,
  }));
 
  const eventData = uniqueeventcategoryData.map((item) => ({
    Event: item,
  }));

  // console.log(riskcategoryData);

  useEffect(() => {
    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);

    // Get the value of the 'target' parameter
    const target = queryParams.get("target");
    setactiveTaget(target);

    // Log the value to the console (you can do anything with it)
    // console.log("Target parameter:", target);
  }, []);


  const goBack = () =>{
    history.goBack();

  }

  const ChangeTab = (data) => {
    setTabValue(data);
  };
  return (
    <div id="SupplyChain" style={{ overflow: "hidden"}}>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div style={{paddingLeft:"10px",cursor:"pointer",paddingTop:"20px", paddingBottom:"20px"}} className="colorCss" onClick={goBack}> <ArrowBackIcon/> Go Back</div>

          <div style={{overflowY: "auto"}} id="main">
            
            <div id="main_div">

              <div className="row">
                
                <div className="col-12">
                <span id="card-supply-title" style={{padding:"10px"}} className="card-title">{activeTaget === null ? "Supply Chain Risk" : activeTaget === "Risk Category" ? "Risk Category" : activeTaget === "Event" ? "Events" : activeTaget === "riskcategory" ? 'Sector' : ''} </span>

                  <div className="card bodyArea">
                    <div className="card-body">
                      <div id="tab_view">
                     

                        <ul
                          className="nav nav-tabs profile-tab nav-justified"
                          role="tablist"
                        >
                          <div style={{gridTemplateColumns:"33% 33% 33%"}} className="tabChatroom">
                          <span
                            id="risktype"
                            onClick={() => ChangeTab("Risk Category")}
                            className={`chatRoomTab ${
                              tabValue === "Risk Category" ? "tabSelected" : ""
                            }`}
                          >
                            Risk Category{" "}
                          </span>

                          <span
                            id="event"
                            onClick={() => ChangeTab("Event")}
                            className={`chatRoomTab ${
                              tabValue === "Event" ? "tabSelected" : ""
                            }`}
                          >
                            Event
                          </span>

                          <span
                            id="riskcategory"
                            onClick={() => ChangeTab("riskcategory")}
                            className={`chatRoomTab ${
                              tabValue === "riskcategory" ? "tabSelected" : ""
                            }`}
                          >
                            Sector
                          </span>

                        </div>


                        </ul>

                      

                        {tabValue === "Risk Category"&& (
                          <div className="tab-content">
                            <div className="tab-pane" id="tab1" role="tabpanel">
                              <div className="card-body">
                                <div
                                  id="marcket_chart"
                                  className="custom_chart"
                                ></div>
                              </div>
                            </div>
                            <div
                              className="tab-pane active"
                              id="tab4"
                              role="tabpanel"
                            >
                              <div className="row bg-body">
                                

                                {riskcategory.map((category,index) =>{
                                  return (
                                    <div key={index} className="col-lg-4 d-flex align-items-stretch">
                                      <div className="card blog-widget w-100">
                                        <div className="card-body">
                                          <span className="boxText">
                                            <Link to={`/details?target=${tabValue}&type=${(category.risk_category)}`}>
                                              {category.risk_category}
                                            </Link>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}

                        {tabValue === "Event" && (
                          <div  className="tab-content">
                            <div className="tab-pane" id="tab1" role="tabpanel">
                              <div className="card-body">
                                <div
                                  id="marcket_chart"
                                  className="custom_chart"
                                ></div>
                              </div>
                            </div>
                            <div
                              className="tab-pane active"
                              id="tab4"
                              role="tabpanel"
                            >
                              <div  className="row bg-body">

                                {eventData.map((events,index) =>{
                                  return (
                                    <div key={index}  className="col-lg-4 d-flex align-items-stretch">
                                      <div className="card blog-widget w-100">
                                        <div className="card-body">
                                        <span className="boxText">
                                            <Link to={`/details?target=${tabValue}&type=${(events.Event)}`}>
                                             {events.Event}
                                            </Link>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                               

                              


                              </div>
                            </div>
                          </div>
                        )}

                        {tabValue === "riskcategory" && (
                          <div className="tab-content">
                            <div className="tab-pane" id="tab1" role="tabpanel">
                              <div className="card-body">
                                <div
                                  id="marcket_chart"
                                  className="custom_chart"
                                ></div>
                              </div>
                            </div>
                            <div
                              className="tab-pane active"
                              id="tab4"
                              role="tabpanel"
                            >
                              <div className="row bg-body">
                               

                                {sector.map((items,index) => {
                                  return(
                                    <div key={index} className="col-lg-4 d-flex align-items-stretch">
                                    <div className="card blog-widget w-100">
                                      <div className="card-body">
                                      <span className="boxText">
                                          <Link to={`/details?target=${tabValue}&type=${(items.sector).toLocaleLowerCase()}`}>
                                            {items.sector}
                                          </Link>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  )
                                })}

                              
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default SuppyChainRisk;
