import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Spinner from "../Layout/Spinner";
import dataJson from "../../data/risk.json";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  ...theme.mixins.toolbar,
}));

const Typewriter = ({ text, speed }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (text) {
      const interval = setInterval(() => {
        if (currentIndex < text.length) {
          setDisplayedText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      }, speed);

      return () => clearInterval(interval);
    }
  }, [text, speed, currentIndex]);

  return <span>{displayedText}</span>;
};

const Details = () => {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataItems, setDataItem] = useState(dataJson);
  const queryParams = new URLSearchParams(window.location.search);

  // Get the value of the 'target' parameter
  const target = queryParams.get("target");
  const parameter = queryParams.get("type");

  // console.log(target)
  // console.log(parameter)

  // const dataCategory1 = dataItems.filter(
  //   (item) => item.categoryType === target
  // );

  let dataCategory = [];

  if (target === "Risk Category") {
    dataCategory = dataItems.filter((item) => item.risk_category === parameter);
  } else if (target === "Event") {
    dataCategory = dataItems.filter((item) => item.event === parameter);
  }

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  // const passtoken = JSON.parse(localStorage.getItem("okta-token-storage"))[
  //   "accessToken"
  // ]["accessToken"];

  //   // Log the value to the console (you can do anything with it)
  // console.log("Target parameter:", parameter);

  // const baseurl = `https://dataasset-marketplace.azurewebsites.net/aipd/cogen/data?${target}=${decodeURI(parameter)}`;

  // const GetSaveReports = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(baseurl, {
  //       headers: {
  //         //Authorization: `Bearer ${passtoken}`,
  // 	  'Accept':'application/json',
  // 	  'x-api-key':'725e37d2-97a8-11ed-a8fc-0242ac120002'
  //       },
  //     });

  //     if (response.ok) {
  //       const jsonData = await response.json();
  //       const postData = jsonData.data.results;
  //       setLoading(false);
  //       setEventData(postData);
  //     }
  //   } catch (err) {
  //     console.error("Error:", err);
  //     setLoading(false);
  //     setEventData([]);
  //   }
  // };

  // console.log(eventData)
  return (
    <div id="Details" style={{ overflow: "hidden" }}>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="main" style={{ overflowY: "auto", position: "relative" }}>
            {loading && (
              <div className="loadingSpinnercss">
                <Spinner />
              </div>
            )}
            {!loading && (
              <div id="main_div">
                <div className="row">
                  <div className="col-12">
                    <div className="back-btn">
                      <div
                        style={{
                          cursor: "pointer",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                        className="colorCss"
                        onClick={goBack}
                      >
                        <ArrowBackIcon /> Go Back
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h4
                          className="card-title"
                          style={{ textTransform: "capitalize" }}
                        >
                          {parameter}
                        </h4>
                        <div id="tab_view">
                          <ul
                            className="nav nav-tabs profile-tab nav-justified"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active text-center"
                                id="tab4-tab"
                                data-bs-toggle="tab"
                                href="#tab4"
                                role="tab"
                                style={{
                                  display: "flex",
                                  gridGap: "10px",
                                  justifyContent: "center",
                                  justifyItems: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <rect
                                    x="3"
                                    y="3"
                                    width="18"
                                    height="18"
                                    rx="2"
                                    ry="2"
                                  />
                                  <line x1="3" y1="9" x2="21" y2="9" />
                                  <line x1="9" y1="21" x2="9" y2="9" />
                                </svg>
                                <span className="d-none d-md-inline-block fw-normal">
                                  Summary
                                </span>
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div className="tab-pane" id="tab1" role="tabpanel">
                              <div className="card-body">
                                <div
                                  id="marcket_chart"
                                  className="custom_chart"
                                ></div>
                              </div>
                            </div>
                            <div
                              className="tab-pane active"
                              id="tab4"
                              role="tabpanel"
                            >
                              <div className="row bg-body">
                                {dataCategory.map((data, index) => (
                                  <>
                                    {data.news !== "" && (
                                      <div
                                        key={index}
                                        className="col-lg-4 d-flex align-items-stretch"
                                      >
                                        <div className="card blog-widget w-100">
                                          <div className="card-body">
                                            <h3
                                              style={{
                                                textTransform: "capitalize",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              {data.event}
                                            </h3>

                                            <div
                                              style={{
                                                display: "flex",
                                                gridGap: "10px",
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              {/* <label className="badge badge-pill bg-light-info text-dark-info font-weight-medium py-1 px-2">
          Marine
        </label> */}
                                              <label
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                className="badge badge-pill bg-light-info text-dark-info font-weight-medium py-1 px-2"
                                              >
                                                {data.risk_category}
                                              </label>
                                              <label
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                className="badge badge-pill bg-light-info text-dark-info font-weight-medium py-1 px-2"
                                              >
                                                {data.risk_type}
                                              </label>
                                              <label
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                                className="badge badge-pill bg-light-danger text-dark-danger font-weight-medium py-1 px-2"
                                              >
                                                {data.Impact_Duration_Category}
                                              </label>
                                            </div>

                                            <div className="text-align-justify">
                                              <p
                                                className="my-3"
                                                id={`outputelement_${index}`}
                                              >
                                                {/* Use Typewriter component */}
                                                {/* {data[2] && <Typewriter text={data[2]} speed={50} />} */}
                                                {data.news}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Details;
