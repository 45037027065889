import React from 'react'

const Spinner = () => {
  return (
    <>
      <span class="loader"></span>
    </>
  )
}

export default Spinner
