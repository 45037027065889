import React, { useState } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// const obtainAccessToken = async () => {
//     try {
//         const tokenUrl = `https://login.microsoftonline.com/${import.meta.env.VITE_AZURE_TENANT_ID}/oauth2/token`;
  
//         const headers = {
//             "Content-Type": "application/x-www-form-urlencoded",
//         };
  
//         const body = new URLSearchParams();
//         body.append('client_id', import.meta.env.VITE_AZURE_CLIENT_ID);
//         body.append('client_secret', import.meta.env.VITE_AZURE_CLIENT_SECRET);
//         body.append('grant_type', 'client_credentials');
//         body.append('resource', 'https://analysis.windows.net/powerbi/api');
  
//         const response = await axios.post(tokenUrl, body, { headers });
//         console.log('access token = ' + response.data.access_token);
//         return response.data.access_token;
//     } catch (error) {
//         console.error(error);
//     }
//   };


  

const Dashboard = () => {

const  embedUrl = "https://app.powerbi.com/reportEmbed?reportId=1513e604-dddf-4f7a-a79f-edf23a459d45&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19"
const id = "1513e604-dddf-4f7a-a79f-edf23a459d45"
const token = "H4sIAAAAAAAEACWUxxKrSBZE_-VtmYjCSmIielEgfIFwwu3wVnjhJubfR9O9z03mOff-548Zn90QZ3_-_We7tcchUQ_CZFUZY0ujjJAoAsVyC-rs8WAgCb4cSrqblLcHp7GQYwF3veHkpAqImV2GzGK3V4bOoeMZJGP3Zdo2cFIgfq8ilqTOounPtaky0snfL42JONp4SXI681XlI10TGGggby9PV8Py2z2dm--Y8c453LhQmoMiYbZxzbP7KnuP7v5UBdmaplPFev4RPbQlw0kZqjIt-FbPXg4pjD4V3CxT96KPAH3b5C73WS-3CxYVlEbGdPLXggcEFeH0VyMDWVTju9ypIk8kdBL3RFWseERVY9tj3qO0PmSR-vw5EcHdrBOOUDYvir2DU2DjjbzKr9NEfRXZqlDIGYNNQFfZUhoin35mbspsWQpLV-wW8ElXru813fq0JZ7W38mL7G2ImH5DoHBXVpM3aOLeh3q7PvOiWeqZ5tQgb-Sn4UizcUnNpLewqufy-Nz7bAaTwNvQuxCbj5GkRHgaxc_d9kjqUxwBuIJF1dzETKMjMub-eokAloDrdwdp1c4gfSqR1uJF2E7sF8XohRJ68fgHUV-4O29WN4JQ2DmXV6iPWL2oAegHxls-Kt8L8HZMYBQL1ujH5wXrBVYPIZdQcr_x6kDeHMoRZCPAl-ENiEiAc5rPvcR1PiwLEIzJ034j8bmDBHXV7mJyi71iGH7yzprePPNQguEaERJno2tqfMVrTgkqzEKzyEU6gycqyoq5vFaPjSLlxs7iKu0kmDdV9sAtpK_dXPQ6Uy3qW0Sg01ntAvmj6CmeubF52GPuTgSFQTFeTwb2EDznpMSvQHlvyD0dKjkw66-__vzrDz-f4zpo-fn_M4G6JYwTNQlkH8Q6jtQrbEhGTGRNsi2s5ElRd3MhD1QQgskKDrZOy6ZU-DXD8tfh6TY9-XclfGZREO1s_r0y3CaAkMoxCqS0cl2J7LXTJfR8TIaapkLok4dvSSyt0wuHqCbo3pftvPn7Tudsv8IgF7msVP3lKTVcUZU__0WDo007zXuPeEywfXwgXrOgN9_VvcD6V_DZ6FWZaffXAeMJ0lA9_fsk3deWcV0_Ygg1jfToDqN2JMXb08KQGig8XFo4sA-uj5o8qExDhNGy99VXPngoUASO7lhzW1JLBzc_Gz8__Ii9E2u5QecZYdbUhl9KzQhG6qYBE8WbY3ybkigNbJ_3f2Y-xyqfFe-3cpTAKjTkpDOU9DF1j2yKfpr8nXLqso_X75z_YldwF1-WWpFOi8j22KmxUZb6yYtswMk-_u3h0aZSpdB9wDoBV7uYCjAp5deELezpBcZAIArszQ_TJ8yKhv0e3UmN22InXYjjdz9551x4MdxcQg_gQUyQmE2SZyc39bakkOza2rD0Q3MhiD85-cqLZaF4hAF6aj8BfiPRGv5s2Jnxksx2uTWpRpKvmhKSUstNm6R8IF1J_mPvuKovMIaPgPq4ODMUk73zqkQw8VDgFTzUr_KqrxhL79_w5bs-XU1m0jaX4k6g5rVy_jY4t4nQkR8HUKYQMLxSQmu3FiVxelw8JP5Nitc0ae_zEH7vsj8aGAVy2Z7LhJ4B0utN4LC4NDP4m_m__wMa0TpUGgYAAA==.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZXhwIjoxNjk2MzIwMjIxLCJhbGxvd0FjY2Vzc092ZXJQdWJsaWNJbnRlcm5ldCI6dHJ1ZX0="
   
  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div
              style={{
                gridGap: "10px",
                justifyContent: "flex-start",
                width: "100%",
                textAlign: "left",
                overflow: "hidden",
                overflowY: "auto",
                height: "calc(100vh - 100px)",
              }}
            >
              <div>
              {/* <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, and qna.
              id: id,
              embedUrl: embedUrl,
              accessToken: token,
              //pageName: 'ReportSectione5bc25aa0357c917eaaa',
              tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
              permissions: models.Permissions.All,
              viewMode: models.ViewMode.View,

              settings: {
                navContentPaneEnabled: false,
                filterPaneEnabled: false,
                persistentFiltersEnabled: true,
                // background: models.BackgroundType.Transparent,

                bars: {
                  actionBar: {
                    visible: true,
                  },
                  statusBar: {
                    visible: false,
                  },
                },
                panes: {
                  filters: {
                    expanded: false,
                    visible: true,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function () {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function () {
                    console.log("Report rendered");
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={"report-style-class"}
          /> */}

                <iframe
                  title="Global Supply Chain Risk Management - V2"
                  width="100%"
                  height="600"
                  src="https://app.powerbi.com/reportEmbed?reportId=f057b201-8105-4f4b-ac50-75fd3397f918&autoAuth=true&ctid=f9233a40-fb1a-464d-b85a-959e3ab8e454"
                  frameBorder="0"
                  allowFullScreen={true}
                ></iframe>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
