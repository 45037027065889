import React, { useState } from 'react'
import Topnavbar from '../nav/Topnavbar'
import SideNavbar from '../nav/SideNavbar'
import Box from '@mui/material/Box';

const MainLayout = () => {

  return (
    <Box>
        <SideNavbar/>
    </Box>
  )
}

export default MainLayout
