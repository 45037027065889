import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import jsondata from "../../data/data.json";
import { Link } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import nodataImage from "../../assets/img/3.png";
import { FaTrash } from "react-icons/fa";
import HistoryIcon from "@mui/icons-material/History";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// const chatBotData = [
//   {
//     "input": "hi",
//     "message": "How can I help you?"
//   },
//   {
//     "input": "hello",
//     "message": "How can I help you?"
//   },
//   {
//     "input": "list the events impacting Damco company?",
//     "message": "No, the events mentioned are not impacting Damco company. They are impacting Evergreen Marine Corporation."
//   }
// ];

const chatBotData = jsondata;

// console.log(JSON.stringify(chatBotData, null, 2));

export default function Home() {
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const chatWindowRef = useRef(null);
  const [tabValue, setTabValue] = useState("tab1"); // Initialize with 'tab1' as the default tab
  const [History, SetHistory] = useState(
    JSON.parse(window.sessionStorage.getItem("HistoryData")) || []
  );

  // console.log(messages);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  useEffect(() => {
    // Scroll to the bottom only if there are messages
    if (messages.length > 0) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  // const handleSendMessage = () => {

  //   // Simulate a chatbot response based on user input
  //   const botResponse = chatBotData.find(item => item.input === userInput.toLowerCase());

  //   const newMessage = { user: true, text: userInput };

  //   if (botResponse) {

  //   //  const typingMessage = { user: false, text: '', link: botResponse.link || null };
  //    const typingMessage = { user: false, text: '', link:null };

  //      setMessages(prevMessages => [...prevMessages, newMessage, typingMessage]);

  //      setTimeout(()=>{// Start typing effect for the bot's response
  //       let i = 0;
  //       let text = botResponse.message;
  //       let speed = 10;

  //       function typeWriter() {

  //         if (i < text.length) {
  //           typingMessage.text += text.charAt(i);
  //           setMessages(prevMessages => [...prevMessages]);

  //           i++;
  //           setTimeout(typeWriter, speed);
  //         } else {
  //           // Scroll to the bottom of the chat window
  //           chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  //         }
  //       };

  //       // Start the typing effect
  //     typeWriter();
  //     if(botResponse.link!=null)
  //     typingMessage.link=botResponse.link;
  //    },5000);

  //   } else {
  //     // const messageText = { user: false, text: "I'm sorry, I don't have a response for that." };
  //     // setMessages(prevMessages => [...prevMessages, newMessage, messageText]);
  //     // chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;

  //     // // Scroll to the top when a new user message is added
  //     // chatWindowRef.current.scrollTop = 0;

  //   const messageText = { user: false, text: '' };
  //   setMessages(prevMessages => [...prevMessages, newMessage, messageText]);

  //   let i = 0;
  //   let text = "I'm sorry, I don't have a response for that.";
  //   let speed = 50;

  //   function typeWriter() {
  //     if (i < text.length) {
  //       messageText.text += text.charAt(i);
  //       setMessages(prevMessages => [...prevMessages]);

  //       i++;
  //       setTimeout(typeWriter, speed);
  //     } else {
  //       // Scroll to the bottom of the chat window
  //       chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  //     }
  //   }

  //   // Start the typing effect for the "I'm sorry" message
  //   typeWriter();
  //   }

  //   setUserInput('');
  // };

  const handleSendMessage = () => {
    const newMessage = { user: true, text: userInput };

    if (userInput.toLowerCase() === "show the scaffold") {
      // If the user input is "show the scaffold," respond with "Sure"
      const responseMessage = { user: false, text: "Sure" };
      setMessages((prevMessages) => [
        ...prevMessages,
        newMessage,
        responseMessage,
      ]);

      // Redirect to the "/Scaffold" page after a short delay
      setTimeout(() => {
        window.location.href = "/Scaffold";
      }, 1000); // Delay for 1 second (1000 milliseconds)
    } else {
      // Handle other user inputs here (your existing logic)
      const botResponse = chatBotData.find(
        (item) => item.input === userInput.toLowerCase()
      );
      const sessionHistory =
        JSON.parse(window.sessionStorage.getItem("HistoryData")) || [];
      // Only add the exampleText to history if it's not already present
      if (!sessionHistory.includes(userInput)) {
        sessionHistory.push(userInput);
        window.sessionStorage.setItem(
          "HistoryData",
          JSON.stringify(sessionHistory)
        );
      }

      if (botResponse) {
        const typingMessage = { user: false, text: "", link: null };

        setMessages((prevMessages) => [
          ...prevMessages,
          newMessage,
          typingMessage,
        ]);

        if (typingMessage.text === "") {
          typingMessage.text = "Typing...";
        }

        setTimeout(() => {
          typingMessage.text = "";
          let i = 0;
          let text = botResponse.message;
          let speed = 10;

          function typeWriter() {
            if (i < text.length) {
              typingMessage.text += text.charAt(i);
              setMessages((prevMessages) => [...prevMessages]);

              i++;
              setTimeout(typeWriter, speed);
            } else {
              chatWindowRef.current.scrollTop =
                chatWindowRef.current.scrollHeight;
            }
          }

          typeWriter();
          SetHistory(sessionHistory);

          if (botResponse.link != null) {
            typingMessage.link = botResponse.link;
          }
        }, 5000);
      } else {
        const messageText = { user: false, text: "" };
        setMessages((prevMessages) => [
          ...prevMessages,
          newMessage,
          messageText,
        ]);
        SetHistory(sessionHistory);

        let i = 0;
        let text = "I'm sorry, I don't have a response for that.";
        let speed = 50;

        function typeWriter() {
          if (i < text.length) {
            messageText.text += text.charAt(i);
            setMessages((prevMessages) => [...prevMessages]);

            i++;
            setTimeout(typeWriter, speed);
          } else {
            chatWindowRef.current.scrollTop =
              chatWindowRef.current.scrollHeight;
          }
        }

        typeWriter();
      }

      setUserInput("");
    }
  };

  const handleExampleClick = (exampleText) => {
    // Set the clicked exampleText as the userInput
    setUserInput(exampleText);
    //   const sessionHistory =
    //   JSON.parse(window.sessionStorage.getItem("HistoryData")) || [];
    //   SetHistory(sessionHistory);
    // // Only add the exampleText to history if it's not already present
    //   if (!sessionHistory.includes(exampleText)) {
    //     sessionHistory.push(exampleText);
    //     window.sessionStorage.setItem("HistoryData", JSON.stringify(sessionHistory));
    //   }

    // Perform any additional actions if needed
    // For example, you can call handleSendMessage here
  };

  // const handleLimitationClick = (limitationText) => {
  //   // Perform actions based on the clicked limitationText
  //   // For example, you can set it as the user's input and call handleSendMessage
  //   setUserInput(limitationText);
  //   handleSendMessage();
  // };

  // const handleCapabilityClick = (capabilityText) => {
  //   // Perform actions based on the clicked capabilityText
  //   // For example, you can set it as the user's input and call handleSendMessage
  //   setUserInput(capabilityText);
  //   handleSendMessage();
  // };

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the Enter key from adding a newline
      handleSendMessage(); // Trigger the custom action
    }
  };

  const ChangeTab = (data) => {
    setTabValue(data);
  };

  const faq = [
    {
      text: "List the events impacting Damco company?",
    },
    {
      text: "Show latest news about evergreen marine",
    },
    {
      text: "Give me supply chain risk summary",
    },
    {
      text: "Show the context scaffold",
    },
    {
      text: "Tell me about CMA CGM performance",
    },
    {
      text: "What are supply chain risk due to suppliers",
    },
    {
      text: "Tell me supply chain risk due to evergreen",
    },
    {
      text: "Tell me supply chain risk due to CMA CGM",
    },
  ];

  const clearSessionData = () => {
    // Clear the History array from session storage
    window.sessionStorage.removeItem("HistoryData");
    SetHistory(JSON.parse(window.sessionStorage.getItem("HistoryData")) || []);
    // You can add additional session data clearing logic here if needed
  };

  const removeFromSessionHistory = (itemToRemove) => {
    SetHistory(JSON.parse(window.sessionStorage.getItem("HistoryData")) || []);

    // Load the current history from session storage
    const sessionHistory =
      JSON.parse(window.sessionStorage.getItem("HistoryData")) || [];

    // Find the index of the item to remove
    const indexToRemove = sessionHistory.indexOf(itemToRemove);
    SetHistory(JSON.parse(window.sessionStorage.getItem("HistoryData")) || []);

    // Check if the item is in the history array
    if (indexToRemove !== -1) {
      // Remove the item from the history array
      sessionHistory.splice(indexToRemove, 1);

      // Update session storage with the modified history array
      window.sessionStorage.setItem(
        "HistoryData",
        JSON.stringify(sessionHistory)
      );
      SetHistory(
        JSON.parse(window.sessionStorage.getItem("HistoryData")) || []
      );
    }
  };
  return (
    <div id="homePage" style={{ overflow: "hidden" }}>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome">
            <div id="mainChatContent">
              <div className="faqSections">
                <div className="">
                  <div className="card text-center">
                    <div className="card-body">
                      <div id="FaqSectionsBot">
                        <div className="tabChatroom">
                          <span
                            id="tab1"
                            onClick={() => ChangeTab("tab1")}
                            className={`chatRoomTab ${
                              tabValue === "tab1" ? "tabSelected" : ""
                            }`}
                          >
                            Example{" "}
                          </span>

                          <span
                            id="tab2"
                            onClick={() => ChangeTab("tab2")}
                            className={`chatRoomTab ${
                              tabValue === "tab2" ? "tabSelected" : ""
                            }`}
                          >
                            History
                          </span>
                        </div>
                      </div>

                      {tabValue === "tab1" && (
                        <>
                          {faq.length !== 0 && (
                            <div>
                              {faq.map((item, index) => (
                                <div
                                  key={index}
                                  className="mt-3 justify-content-center"
                                >
                                  <div className="bg-dark-card">
                                    <div className="text-start faqtext">
                                      <span
                                        onClick={() =>
                                          handleExampleClick(item.text)
                                        }
                                      >
                                        {item.text}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          {faq.length === 0 && (
                            <>
                              <div
                                style={{
                                  height: "60vh",
                                  display: "grid",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "absolute",
                                  top: 30,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <div>
                                  <img
                                    src={nodataImage}
                                    width={200}
                                    alt="nodata"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "grid",
                                    gridGap: "6px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      color: "gray",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    No example available
                                  </span>
                                  <span
                                    style={{
                                      width: "100%",
                                      display: "block",
                                      margin: "auto",
                                      color: "gray",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    Despite the absence of an FAQ section, we
                                    are here to assist you. Feel free to reach
                                    out to our support team with any questions
                                    or concerns you may have.{" "}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {tabValue === "tab2" && (
                        <>
                          {History.length === 0 && (
                            <>
                              <div
                                style={{
                                  height: "60vh",
                                  display: "grid",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "absolute",
                                  top: 30,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <div>
                                  <img
                                    src={nodataImage}
                                    width={200}
                                    alt="nodata"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "grid",
                                    gridGap: "6px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      color: "gray",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    No History Available
                                  </span>
                                  <span
                                    style={{
                                      width: "100%",
                                      display: "block",
                                      margin: "auto",
                                      color: "gray",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {" "}
                                    We do not have any history data at the
                                    moment{" "}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}

                          {History.length !== 0 && (
                            <>
                              {History.map((item, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className="mt-3 justify-content-center"
                                    >
                                      <div className="bg-dark-card">
                                        <div
                                          style={{
                                            gridGap: "7px",
                                            alignItems: "baseline",
                                          }}
                                          className="text-start faqtext"
                                        >
                                          <span
                                            onClick={() =>
                                              handleExampleClick(item)
                                            }
                                          >
                                            {item}
                                          </span>

                                          <span
                                            style={{
                                              cursor: "pointer",
                                              display: "grid",
                                            }}
                                            onClick={() =>
                                              removeFromSessionHistory(item)
                                            }
                                          >
                                            <FaTrash
                                              fontSize={12}
                                              style={{
                                                color: "#253978",
                                                paddingTop: "2px",
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="textbodyarea"
                className="card chatting-box mb-0"
                style={{ display: "block", width: "100%", textAlign: "left" }}
                ref={chatWindowRef}
              >
                {messages.length === 0 && (
                  <>
                    {History.length === 0 && (
                      <div style={{ height: "calc(100vh - 180px)" }}>
                        <div
                          style={{
                            height: "50vh",
                            display: "grid",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            top: 30,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            textAlign: "center",
                          }}
                        >
                          <div>
                            <img src={nodataImage} width={250} alt="nodata" />
                          </div>
                          <div
                            style={{
                              display: "grid",
                              gridGap: "6px",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                color: "gray",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              No response Available
                            </span>
                            <span
                              style={{
                                width: "100%",
                                display: "block",
                                margin: "auto",
                                color: "gray",
                                fontSize: "14px",
                              }}
                            >
                              {" "}
                              Your questions will help to address any query or
                              concerns
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {History.length !== 0 && (
                      <div style={{ height: "calc(100vh - 180px)" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span className="Recent_history">Recent Search</span>
                          <span
                            style={{
                              justifyContent: "flex-end",
                              paddingRight: "1rem",
                              cursor: "pointer",
                            }}
                            className="Recent_Clear"
                            onClick={clearSessionData}
                          >
                            Clear
                          </span>
                        </div>

                        {History.slice()
                          .reverse()
                          .slice(0, 5)
                          .map((items, index) => {
                            return (
                              <div className="historyData" key={index}>
                                <div className="historyItems">
                                  <span>
                                    <HistoryIcon sx={{ color: "#253978" }} />
                                  </span>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => handleExampleClick(items)}
                                  >
                                    {items}
                                  </span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      display: "grid",
                                    }}
                                    onClick={(e) =>
                                      removeFromSessionHistory(items)
                                    }
                                  >
                                    <FaTrash
                                      fontSize={12}
                                      style={{ color: "#253978" }}
                                    />
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </>
                )}

                {messages.length !== 0 && (
                  <>
                    <div style={{ minHeight: "100px" }} className="card-body">
                      <div
                        style={{
                          height: "auto",
                          width: "100%",
                          display: "grid",
                          gridGap: "10px",
                        }}
                      >
                        <div className="chat-messages">
                          {messages.map((message, index) => (
                            <div
                              key={index}
                              id={`message_${
                                message.user ? "user" : "bot"
                              }_${index}`}
                              className={`message ${
                                message.user ? "user" : "bot"
                              }`}
                            >
                              {message.text}{" "}
                              {message.link !== null &&
                                message.link !== undefined && (
                                  <Link to={`/${message.link}`}>
                                    Click Here
                                  </Link>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div
                  className="card-body border-top border-bottom"
                  id="textboxChats"
                >
                  <div className="">
                    <div className="col-12 inputTextboxHome">
                      <div className="input-group mt-0 mb-0">
                        <input
                          id="chat_pdf_input"
                          placeholder="Send a Message"
                          className="message-type-box form-control border-0"
                          type="text"
                          value={userInput}
                          onChange={handleInputChange}
                          autoComplete="off"
                          onKeyPress={handleInputKeyPress}
                        />
                        <span
                          id="send_pdf_chat"
                          className="btn btn-light-success text-success sendbtn borderColorNew"
                          onClick={handleSendMessage}
                        >
                          <SendIcon className="colorCss" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}
